import { useEffect, useMemo, useState } from "react";
import { fromUnixTime, formatDistanceToNowStrict } from "date-fns";

function useElapsedTime(startTime?: number) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [elapsedTimeStr, setElapsedTimeStr] = useState("");

    const startDate = useMemo(() =>
        startTime ? fromUnixTime(startTime) : null
        , [startTime]);

    const isFuture = useMemo(() =>
        startDate ? startDate > currentTime : false
        , [startDate, currentTime]);

    useEffect((): (() => void) => {
        if (!startDate) {
            return () => undefined;
        }

        const calculateElapsedTime = () => {
            const now = new Date();
            setCurrentTime(now);
            setElapsedTimeStr(
                formatDistanceToNowStrict(startDate, { addSuffix: !isFuture })
            );
        };

        calculateElapsedTime();
        const interval = setInterval(calculateElapsedTime, 1000);

        return () => clearInterval(interval);
    }, [startDate, isFuture]);

    return {
        elapsedTime: elapsedTimeStr,
        isFuture
    };
}

export default useElapsedTime;