import React from "react";
import { Box, Stack } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { formatPeriod } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";


type CompactSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
    startTime?: number;
};

function CompactSoccerDesign({ gameLiveData, startTime }: CompactSoccerDesignProps) {
    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{
                    height: "16px",
                }} />
            </Box>
            <Box>
                <Stack direction="row" gap={1} alignItems="center" mt={0.5}>
                    <Stack direction="column" spacing={1} alignItems="start" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" spacing={0} alignItems="center" mt={1}>
                        <StyledTypography variant="caption" fontSize={10}>{formatPeriod(period)}</StyledTypography>
                        <StyledTypography variant="caption" fontSize={10}>{formattedGameTime}</StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactSoccerDesign;
