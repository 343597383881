import React from "react";
import { Box, Stack } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { LiveDataDesign } from "../DesignEnum";


type CompactIceHockeyDesignProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment;
    startTime?: number;
};

const formatPeriod = (period: string | undefined) => {
    if (!period || period === "Halftime") return "";

    const periodNumber = parseInt(period.replace("P", ""), 10);

    // Determine the appropriate suffix
    let suffix = "th"; // Default suffix
    if (periodNumber % 10 === 1 && periodNumber % 100 !== 11) {
        suffix = "st";
    } else if (periodNumber % 10 === 2 && periodNumber % 100 !== 12) {
        suffix = "nd";
    } else if (periodNumber % 10 === 3 && periodNumber % 100 !== 13) {
        suffix = "rd";
    }
    return `${periodNumber}${suffix}`;
};

function CompactIceHockeyDesign({ gameLiveData, startTime }: CompactIceHockeyDesignProps) {
    const {
        homeTeamGoalsByPeriod = [0],
        awayTeamGoalsByPeriod = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
    } = gameLiveData;

    const homeTeamScore = homeTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const awayTeamScore = awayTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const formattedPeriod = formatPeriod(period);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{
                    height: "16px",
                }} />
            </Box>
            <Box>
                <Stack direction="row" spacing={0.5} alignItems="center" mt={0.5}>
                    <Stack direction="column" spacing={1} alignItems="start" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore} isServing={!homeTeamHasPossession} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={homeTeamScore} isServing={homeTeamHasPossession} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" spacing={0} alignItems="center" mt={1}>
                        <StyledTypography variant="caption" fontSize={10}>{formattedPeriod}</StyledTypography>
                        <StyledTypography variant="caption" fontSize={10}>{formattedGameTime}</StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactIceHockeyDesign;
