import React, { useEffect } from "react";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { useTranslations } from "next-intl";
import { motion, AnimatePresence } from "framer-motion";
import { LiveDataDesign } from "./DesignEnum";

type OddsScoreIndicatorProps = {
    score?: string;
    isServing: boolean;
    isInRedZone?: boolean;
    design: LiveDataDesign;
    view?: string;
};

function ScoreIndicatorLiveData({ score, isServing, isInRedZone, design, view }: OddsScoreIndicatorProps) {
    const theme = useTheme();
    const t = useTranslations("liveData");

    let backgroundColor;
    if (isServing && isInRedZone) {
        backgroundColor = theme.palette.error.main;
    } else if (isServing) {
        backgroundColor = theme.palette.text.primary;
    } else {
        backgroundColor = "transparent";
    }

    const [displayedScore, setDisplayedScore] = React.useState(score);
    const [scoreChange, setScoreChange] = React.useState<string | null>(null);
    const previousScoreRef = React.useRef<string | undefined>(score);
    const previousViewRef = React.useRef<string | undefined>(view);

    useEffect(() => {
        if (score !== previousScoreRef.current) {
            const isNumeric = (str: string | undefined) => str?.match(/^-?\d+$/) !== null;

            if (isNumeric(score) && isNumeric(previousScoreRef.current)) {
                const newScore = parseInt(score || "0", 10);
                const oldScore = parseInt(previousScoreRef.current || "0", 10);
                const diff = newScore - oldScore;

                if (diff > 0 && view === previousViewRef.current) {
                    setScoreChange(`+${diff}`);

                    setTimeout(() => {
                        setScoreChange(null);
                        setDisplayedScore(score);
                    }, 1000);
                } else {
                    // Silently update for negative changes or resets
                    setDisplayedScore(score);
                }
            } else {
                setDisplayedScore(score);
            }

            previousScoreRef.current = score;
        }
        previousViewRef.current = view;
    }, [score, view]);

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between" position="relative">
            {design === LiveDataDesign.DEFAULT && (
                <StyledTypography variant="body2" textAlign="right" sx={{ fontWeight: "bold" }}>
                    {score}
                </StyledTypography>
            )}
            {design === LiveDataDesign.DETAILED && (
                <StyledTypography variant="body2" textAlign="right" sx={{ fontWeight: "bold" }}>
                    {displayedScore}
                </StyledTypography>
            )}
            <Tooltip title={isInRedZone ? t("red_zone_tooltip") : ""}>
                <Box
                    sx={{
                        width: "6px",
                        height: "6px",
                        borderRadius: "50%",
                        backgroundColor,
                        ml: 0.5,
                        zIndex: 0,
                    }}
                />
            </Tooltip>
            {design === LiveDataDesign.COMPACT && (
                <StyledTypography variant="body2" textAlign="left" sx={{ fontWeight: "bold" }}>
                    {score}
                </StyledTypography>
            )}
            <AnimatePresence>
                {design === LiveDataDesign.DETAILED && scoreChange && (
                    <motion.div
                        initial={{ opacity: 0, y: -20, x: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        style={{ position: "absolute", top: "-5px", right: "-1px", zIndex: 1 }}
                    >
                        <StyledTypography variant="caption" sx={{ fontWeight: "bold", color: theme.palette.success.main }}>
                            {scoreChange}
                        </StyledTypography>
                    </motion.div>
                )}
            </AnimatePresence>
        </Stack>
    );
}

export default ScoreIndicatorLiveData;
