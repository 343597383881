import React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import ElapsedTime from "../ElapsedTime";

type CompactTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    startTime: number;
};

function CompactTennisDesign({ gameLiveData, startTime }: CompactTennisDesignProps) {
    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
    } = gameLiveData;

    const currentSetIndex = Math.max((player1GamesBySet ?? []).length - 1, 0);

    const calculateSetsWon = (playerGames: number[], opponentGames: number[]) =>
        playerGames.reduce((setsWon, games, index) =>
            games >= 6 && games > opponentGames[index] && (games - opponentGames[index] >= 2 || games >= 7) ? setsWon + 1 : setsWon, 0);

    const player1SetsWon = calculateSetsWon(player1GamesBySet ?? [], player2GamesBySet ?? []);
    const player2SetsWon = calculateSetsWon(player2GamesBySet ?? [], player1GamesBySet ?? []);

    const player1Score = player1Points?.toString();
    const player2Score = player2Points?.toString();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{ height: "16px" }} />
            </Box>
            <Box>

                <Stack direction="row" gap={isMobile ? 1 : 1.5} alignItems="center">
                    <Stack direction="column" gap={1.3} alignItems="center" minWidth={20} width={20}>
                        <ScoreIndicatorLiveData score={player1Score} isServing={isPlayer1Serving} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={player2Score} isServing={!isPlayer1Serving} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <StyledTypography color="text.primary" variant="caption" >
                            {(player1GamesBySet ?? [])[currentSetIndex] ?? 0}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.secondary">
                            Game
                        </StyledTypography>
                        <StyledTypography color="text.primary" variant="caption" >
                            {(player2GamesBySet ?? [])[currentSetIndex] ?? 0}
                        </StyledTypography>
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <StyledTypography color="text.primary" variant="caption" >
                            {player1SetsWon}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.secondary">
                            Set
                        </StyledTypography>
                        <StyledTypography color="text.primary" variant="caption" >
                            {player2SetsWon}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactTennisDesign;