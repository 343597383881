import React from "react";
import { Box, Stack } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { formatPeriod } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";


type CompactBasketballDesignProps = {
    gameLiveData: BasketballLiveDataTypeFragment;
    startTime: number;
};

function CompactBasketballDesign({ gameLiveData, startTime }: CompactBasketballDesignProps) {
    const {
        homeTeamTotalPointsByQuarter = [0],
        awayTeamTotalPointsByQuarter = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const awayTeamScore = awayTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const formattedPeriod = formatPeriod(period);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{
                    height: "16px",
                }} />
            </Box>
            <Box>

                <Stack direction="row" spacing={0.5} alignItems="center" mt={0.5}>
                    <Stack direction="column" spacing={1} alignItems="start" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore} isServing={!homeTeamHasPossession} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={homeTeamScore} isServing={homeTeamHasPossession} design={LiveDataDesign.COMPACT} />
                    </Stack>
                    <Stack direction="column" spacing={0} alignItems="center" mt={1}>
                        <StyledTypography variant="caption" fontSize={10}>{formattedPeriod}</StyledTypography>
                        <StyledTypography variant="caption" fontSize={10}>{formattedGameTime}</StyledTypography>
                    </Stack>
                </Stack>
                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactBasketballDesign;
